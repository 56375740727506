import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'

import types from './types'
import { generalActions } from '../general'
import { authActions } from '../auth'
import errorPaser from 'util/laravel_error_parser'
import { getImpersonatedUser, setImpersonatedUser } from 'util/impersonation'

const postSignAgreement = async ({ form }) => {
  return await axios.post('/api/shelter/agreement', form)
}

const postProfile = async (user, { with_logo: withLogo }) => {
  const bodyFormData = new FormData()

  Object.keys(user).forEach((k) => {
    if (k !== 'logo') {
      bodyFormData.append(k, user[k])
    }

    if (k === 'shelter_verified') {
      bodyFormData.append(k, +user[k])
    }
  })

  if (withLogo) {
    bodyFormData.append('logo', user.logo)
  }

  return await axios({
    method: 'post',
    url: `api/common/profile/${user.id}`,
    data: bodyFormData,
    headers: { 'Content-Type': `multipart/form-data` }
  })
}

const postLogin = async ({ payload }) => {
  await axios.get('sanctum/csrf-cookie')
  return await axios.post('/api/auth/login', payload)
}

const postLogout = async () => {
  return await axios.post('/logout')
}

const getCheckIsAuthenticated = async () => {
  await axios.get('sanctum/csrf-cookie')
  const cuddlyUser = getImpersonatedUser()

  if (cuddlyUser) {
    axios.defaults.headers.common['X-Cuddly-User'] = cuddlyUser
  }

  return await axios.get('api/authenticated')
}

const postResetPassword = async ({ payload }) => {
  return await axios.post('/api/password-reset', payload)
}

const postResetLink = async ({ payload }) => {
  return await axios.post('/api/password-link', payload)
}

const postExternalLogin = async (loginString) => {
  await axios.get('sanctum/csrf-cookie')
  return await axios.post('/api/external-login', { login_string: loginString })
}

const putPasswordUpdate = async ({ payload }) => {
  const { id, ...body } = payload
  return await axios.put(`/api/common/password/${id}`, body)
}

function* doResetLink(action) {
  yield put(generalActions.isLoading())
  try {
    const response = yield call(postResetLink, action)

    if (action?.callback) {
      action.callback()
    }

    yield put(generalActions.setSuccess(response.data.message))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doExternalLogin({ login_string: loginString }) {
  yield put(generalActions.setError(false))
  yield put(generalActions.isLoading())

  try {
    const response = yield call(postExternalLogin, loginString)
    const jwtToken = response.data.user.token

    yield put(authActions.setIsAuthenticated(true, response.data.user))

    yield put(generalActions.setSuccess(response.data.message))
    yield put(generalActions.isNotLoading())

    localStorage.setItem('token', jwtToken)
    axios.defaults.headers.common.Authorization = 'Bearer ' + jwtToken
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doResetPassword(action) {
  yield put(generalActions.isLoading())
  try {
    const response = yield call(postResetPassword, action)

    if (action?.callback) {
      action.callback()
    }

    yield put(generalActions.setSuccess(response.data.message))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doSignAgreement(action) {
  yield put(generalActions.isLoading())
  try {
    yield call(postSignAgreement, action)

    if (action?.callback) {
      action.callback()
    }

    yield put(authActions.agreementSigned())
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doProfile(action) {
  yield put(generalActions.isLoading())
  const user = action.form
  try {
    const response = yield call(postProfile, user, action)

    if (action?.callback) {
      action.callback()
    }

    yield put(
      authActions.profileRefreshAfterUpdate(response.data.user_resource)
    )
    yield put(generalActions.setSuccess(response.data.message))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doLogin(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(postLogin, payload)
    const { user } = response.data

    if (user?.role === 'user') {
      const error = {
        response: {
          status: 400,
          data: {
            error: 'User or password mismatch'
          }
        }
      }
      yield put(generalActions.setError(errorPaser(error)))
    } else {
      const jwtToken = user.token

      // Deep copy the user object to avoid mutation
      const userCopy = JSON.parse(JSON.stringify(user))

      // Inject 'partner_page_settings' with icon into both shelter and pantry menus
      if (
        userCopy.is_pet_pantry ||
        userCopy.registered_as === 'shelter' ||
        userCopy.registered_as === 'pantry'
      ) {
        userCopy.menu = userCopy.menu || {}

        // Add to shelter menu
        userCopy.menu.shelter = userCopy.menu.shelter || {}
        userCopy.menu.shelter.profile = userCopy.menu.shelter.profile || {}
        userCopy.menu.shelter.profile.partner_page_settings = {
          visible: true,
          iconComponent: 'NewRedOvalNotification'
        }

        // Add to pantry menu
        userCopy.menu.pantry = userCopy.menu.pantry || {}
        userCopy.menu.pantry.profile = userCopy.menu.pantry.profile || {}
        userCopy.menu.pantry.profile.partner_page_settings = {
          visible: true,
          iconComponent: 'NewRedOvalNotification'
        }
      }

      yield put(authActions.setIsAuthenticated(true, userCopy))
      yield put(generalActions.isNotLoading())
      localStorage.setItem('token', jwtToken)
      axios.defaults.headers.common.Authorization = 'Bearer ' + jwtToken
      setTimeout(() => {
        window.location.href = '/'
      }, 100)
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doLogout() {
  yield put(generalActions.isLoading())
  try {
    yield call(postLogout)

    yield put(authActions.setIsAuthenticated(false))
    yield put(generalActions.isNotLoading())

    // remove impersonations from local storage
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('cuddly_user_') || key.startsWith('act_as_pantry_')) {
        localStorage.removeItem(key)
      }
    })
    sessionStorage.removeItem('hide_agreement_modal')

    axios.defaults.headers.common.Authorization = null
    delete axios.defaults.headers.common['X-Cuddly-User']
    setTimeout(() => {
      window.location.href = '/'
    }, 1)
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* checkIsAuthenticated() {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getCheckIsAuthenticated)
    const user = response.data.user

    const actAsPantry = localStorage.getItem(`act_as_pantry_${user.id}`)

    if (user.can_back_to_admin && user.registered_as !== 'sadmin') {
      const cuddlyUser = user.id
      setImpersonatedUser(cuddlyUser)
      axios.defaults.headers.common['X-Cuddly-User'] = cuddlyUser
    }

    if (actAsPantry) {
      user.registered_as = 'pantry'
    }

    // Deep copy the user object to avoid mutation
    const userCopy = JSON.parse(JSON.stringify(user))

    // Inject 'partner_page_settings' with icon into both shelter and pantry menus
    if (
      userCopy.is_pet_pantry ||
      userCopy.registered_as === 'shelter' ||
      userCopy.registered_as === 'pantry'
    ) {
      userCopy.menu = userCopy.menu || {}

      // Add to shelter menu
      userCopy.menu.shelter = userCopy.menu.shelter || {}
      userCopy.menu.shelter.profile = userCopy.menu.shelter.profile || {}
      userCopy.menu.shelter.profile.partner_page_settings = {
        visible: true,
        iconComponent: 'NewRedOvalNotification'
      }

      // Add to pantry menu
      userCopy.menu.pantry = userCopy.menu.pantry || {}
      userCopy.menu.pantry.profile = userCopy.menu.pantry.profile || {}
      userCopy.menu.pantry.profile.partner_page_settings = {
        visible: true,
        iconComponent: 'NewRedOvalNotification'
      }
    }

    yield put(authActions.setIsAuthenticated(true, userCopy))
  } catch (err) {
    if (err?.response?.status === 401) {
      yield put(authActions.setIsAuthenticated(false))
    }
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doPasswordUpdate(action) {
  yield put(generalActions.isLoading())
  try {
    const response = yield call(putPasswordUpdate, action)
    yield put(generalActions.setSuccess(response.data.message))

    if (action?.callback) {
      action.callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

/** Watchers **/
function* watchDoLogin() {
  yield takeLatest(types.SAGA_DO_LOGIN, doLogin)
}

function* watchDoLogout() {
  yield takeLatest(types.SAGA_DO_LOGOUT, doLogout)
}

function* watchCheckIsAuthenticated() {
  yield takeLatest(types.SAGA_CHECK_IS_AUTHENTICATED, checkIsAuthenticated)
}

function* watchDoProfile() {
  yield takeLatest(types.SAGA_UPDATE_PROFILE, doProfile)
}

function* watchDoSignAgreement() {
  yield takeLatest(types.SAGA_SIGN_AGREEMENT, doSignAgreement)
}

function* watchSagaResetPassword() {
  yield takeLatest(types.SAGA_RESET_PASSWORD, doResetPassword)
}

function* watchSagaResetLink() {
  yield takeLatest(types.SAGA_RESET_LINK, doResetLink)
}

function* watchSagaExternalLogin() {
  yield takeLatest(types.SAGA_EXTERNAL_LOGIN, doExternalLogin)
}

function* watchSagaPasswordUpdate() {
  yield takeLatest(types.SAGA_PASSWORD_UPDATE, doPasswordUpdate)
}

/**
 * Exporting all watchers
 */
export default function* rootSaga() {
  yield all([
    watchDoLogin(),
    watchDoLogout(),
    watchCheckIsAuthenticated(),
    watchDoProfile(),
    watchDoSignAgreement(),
    watchSagaResetPassword(),
    watchSagaResetLink(),
    watchSagaExternalLogin(),
    watchSagaPasswordUpdate()
  ])
}
