import { combineReducers } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr'
import generalReducer from 'store/general'
import authReducer from 'store/auth'
import shelterReducer from 'store/shelter'
import emailReducer from 'store/email'
import fundReducer from 'store/fund'
import adminOrdersReducer from 'store/admin_orders'
import campaignReducer from 'store/campaign'
import wishlistReducer from 'store/wishlist'
import storiesReducer from 'store/stories'
import shippingAddressReducer from './shipping_address'
import thankYouEmailReducer from './thank_you_email'
import multipleCampaignEmailReducer from './multiple_campaign_email'
import paymentReconsolidationReducer from './reports/payment_reconsolidation'
import ordersReportReducer from './reports/orders'
import pantryStatsReducer from './pantry/statistics'
import pantryCampaignReducer from './pantry/campaign'
import pantryDonationsReducer from './pantry/reports/donations'
import pantryThankYouEmailReducer from './pantry/emails/thank_you_email'
import pantryAgreementReducer from './pantry/agreements'
import emailSubjectReducer from './admin/emails/subjects'
import signUpReducer from './sign_up'
import documentationReducer from './documentation'
import generalMetricsReducer from './admin/payouts/general_metrics'
import usersReducer from './admin/users'

const rootReducer = (state, action) =>
  combineReducers({
    general: generalReducer,
    auth: authReducer,
    shelter: shelterReducer,
    email: emailReducer,
    fund: fundReducer,
    admin_order: adminOrdersReducer,
    campaign: campaignReducer,
    wishlist: wishlistReducer,
    stories: storiesReducer,
    shippingAddress: shippingAddressReducer,
    thankYouEmail: thankYouEmailReducer,
    multipleCampaignEmail: multipleCampaignEmailReducer,
    paymentReconsolidation: paymentReconsolidationReducer,
    ordersReport: ordersReportReducer,
    pantryStats: pantryStatsReducer,
    pantryCampaign: pantryCampaignReducer,
    pantryDonations: pantryDonationsReducer,
    pantryThankYouEmail: pantryThankYouEmailReducer,
    pantryAgreement: pantryAgreementReducer,
    toastr: toastrReducer,
    emailSubject: emailSubjectReducer,
    signUp: signUpReducer,
    documentation: documentationReducer,
    generalMetrics: generalMetricsReducer,
    users: usersReducer
  })(action.type === 'RESET_APP_STATE' ? undefined : state, action)

export default rootReducer
