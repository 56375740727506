import types from './types'

const initialState = {
  multipleCampaignEmailForm: {
    to: '',
    subject: '',
    pets: [],
    scheduleDate: new Date().setDate(new Date().getDate() + 1),
    type: 1,
    template: '',
    schedule_hour: '',
    email_list: [],
    templates: [],
    subjects: []
  }
}

const multipleCampaignEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MULTIPLE_CAMPAIGN_EMAIL_FORM: {
      const { pets, preview, ...data } = action.data

      return {
        multipleCampaignEmailForm: {
          ...state.multipleCampaignEmailForm,
          ...data,
          pets
        }
      }
    }

    case types.UPDATE_MULTIPLE_CAMPAIGN_EMAIL_FORM: {
      const { key, value, petId } = action
      const { pets } = state.multipleCampaignEmailForm

      if (key !== 'campaigns') {
        return {
          multipleCampaignEmailForm: {
            ...state.multipleCampaignEmailForm,
            [key]: value
          }
        }
      }

      const index = pets.findIndex((pet) => pet.id === petId)
      const pet = pets[index]
      pet.description = value

      return {
        multipleCampaignEmailForm: {
          ...state.multipleCampaignEmailForm,
          pets: [...pets.slice(0, index), pet, ...pets.slice(index + 1)]
        }
      }
    }

    case types.RESET_MULTIPLE_CAMPAIGN_EMAIL_FORM: {
      return {
        multipleCampaignEmailForm: {
          ...initialState.multipleCampaignEmailForm
        }
      }
    }

    default:
      return state
  }
}

export default multipleCampaignEmailReducer
