import types from './types'

const sagaGetMultipleCampaignEmail = (ids) => {
  return {
    type: types.SAGA_GET_MULTIPLE_CAMPAIGN_EMAIL,
    ids
  }
}

const sagaPostMultipleCampaignEmail = (form, callback) => {
  return {
    type: types.SAGA_POST_MULTIPLE_CAMPAIGN_EMAIL,
    form,
    callback
  }
}

const setMultipleCampaignEmailForm = (data) => {
  return {
    type: types.SET_MULTIPLE_CAMPAIGN_EMAIL_FORM,
    data
  }
}

const updateMultipleCampaignEmailForm = (key, value, petId) => {
  return {
    type: types.UPDATE_MULTIPLE_CAMPAIGN_EMAIL_FORM,
    key,
    value,
    petId
  }
}

const resetMultipleCampaignEmailForm = () => {
  return {
    type: types.RESET_MULTIPLE_CAMPAIGN_EMAIL_FORM
  }
}

export default {
  sagaGetMultipleCampaignEmail,
  sagaPostMultipleCampaignEmail,
  setMultipleCampaignEmailForm,
  updateMultipleCampaignEmailForm,
  resetMultipleCampaignEmailForm
}
