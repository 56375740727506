import types from './types'

const DEFAULT_OPTIONS = [
  {
    value: '',
    label: 'Select'
  }
]
const DEFAULT_RECIPIENTS = [
  {
    value: 'allEmails',
    label: 'All Donors (0)'
  },
  {
    value: 'emailList',
    label: 'My Donors List (0)'
  },
  {
    value: 'allEngaged',
    label: 'All Engaged (0)'
  }
]

const FORM = {
  title: '',
  name: '',
  video_url: '',
  description: '',
  status: '',
  should_email: false,
  send_list: '',
  preview: '',
  pet_id: null,
  subject: '',
  previewPetName: ''
}

const INITIAL_STATE = {
  paginator: {
    data: []
  },
  publishedStoryId: null,
  form: FORM,
  images: [],
  removedImages: [],
  recipients: [...DEFAULT_OPTIONS, ...DEFAULT_RECIPIENTS],
  campaigns: DEFAULT_OPTIONS,
  subjects: DEFAULT_OPTIONS,
  exhausted_email_list: false,
  templates: [],
  campaignCover: ''
}

const storiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_EXHAUSTED_STORIES_EMAIL_LIST: {
      const { fetching } = action

      return {
        ...state,
        exhausted_email_list: fetching,
        recipients: fetching
          ? [...DEFAULT_OPTIONS, ...DEFAULT_RECIPIENTS]
          : state.recipients
      }
    }

    case types.SET_SUCCESS_STORY_LIST: {
      return {
        ...state,
        paginator: action.payload
      }
    }

    case types.SET_SUCCESS_STORY_FORM: {
      const { images = [], ...form } = action.payload

      return {
        ...state,
        form,
        images
      }
    }

    case types.UPDATE_SUCCESS_STORY_FORM: {
      const { key, value } = action.payload

      return {
        ...state,
        form: {
          ...state.form,
          [key]: value
        }
      }
    }

    case types.RESET_SUCCESS_STORY_FORM: {
      return {
        ...state,
        form: FORM,
        images: [],
        removedImages: [],
        publishedStoryId: null,
        subjects: DEFAULT_OPTIONS,
        recipients: [...DEFAULT_OPTIONS, ...DEFAULT_RECIPIENTS],
        exhausted_email_list: false,
        campaigns: DEFAULT_OPTIONS
      }
    }

    case types.RESET_SUCCESS_STORY_LIST: {
      return {
        ...state,
        paginator: {
          data: []
        }
      }
    }

    case types.UPDATE_SUCCESS_STORY_IMAGES: {
      const { images } = action

      return {
        ...state,
        images: images ?? []
      }
    }

    case types.REMOVE_SUCCESS_STORY_IMAGE: {
      const tmp = [...(state.removedImages ?? [])]
      tmp.push(action.id)

      return {
        ...state,
        removedImages: [...new Set(tmp)]
      }
    }

    case types.UPDATE_STORIES_EMAIL_LIST: {
      const tmp = action?.data?.email_list || []

      return {
        ...state,
        recipients: [...DEFAULT_OPTIONS, ...tmp],
        exhausted_email_list: false
      }
    }

    case types.SET_SUCCESS_STORY_CAMPAIGNS: {
      return {
        ...state,
        campaigns: [...DEFAULT_OPTIONS, ...action.campaigns]
      }
    }

    case types.RESET_SUCCESS_STORY_EMAIL_LIST: {
      return {
        ...state,
        form: {
          ...state.form,
          subject: ''
        },
        recipients: DEFAULT_OPTIONS,
        subjects: DEFAULT_OPTIONS
      }
    }

    case types.SET_SUCCESS_STORY_SUBJECTS: {
      const tmp =
        action.subjects?.map((subject) => {
          return {
            value: subject,
            label: (
              <div className="flex items-center">
                <div className="flex flex-col whitespace-normal">
                  <span>{subject}</span>
                </div>
              </div>
            )
          }
        }) || []

      return {
        ...state,
        subjects: DEFAULT_OPTIONS.concat(tmp)
      }
    }

    case types.SET_SUCCESS_STORY_CAMPAIGN_COVER: {
      return {
        ...state,
        campaignCover: action.cover
      }
    }

    case types.SET_SUCCESS_STORY_TEMPLATES: {
      return {
        ...state,
        templates: action.templates
      }
    }

    default:
      return state
  }
}

export default storiesReducer
