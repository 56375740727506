import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from '@redux-devtools/extension'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import authActions from './auth/actions'
import generalActions from './general/actions'
import shelterActions from './shelter/actions'
import emailActions from './email/actions'
import fundActions from './fund/actions'
import adminOrderActions from './admin_orders/actions'
import campaignActions from './campaign/actions'
import wishlistActions from './wishlist/actions'
import storiesActions from './stories/actions'
import shippingAddressActions from './shipping_address/actions'
import thankYouEmailActions from './thank_you_email/actions'
import multipleCampaignEmailActions from './multiple_campaign_email/actions'
import paymentReconsolidationActions from './reports/payment_reconsolidation/actions'
import ordersReportActions from './reports/orders/actions'
import pantryStatsActions from './pantry/statistics/actions'
import pantryCampaignActions from './pantry/campaign/actions'
import pantryDonationsActions from './pantry/reports/donations/actions'
import pantryThankYouEmailActions from './pantry/emails/thank_you_email/actions'
import pantryAgreementActions from './pantry/agreements/actions'
import emailSubjectsActions from './admin/emails/subjects/actions'
import signUpActions from './sign_up/actions'
import documentationActions from './documentation/actions'
import generalMetricsActions from './admin/payouts/general_metrics/actions'
import usersActions from './admin/users/actions'

import rootReducer from 'store/root-reducer'
import { authSagas } from 'store/auth'
import { shelterSagas } from 'store/shelter'
import { emailSagas } from 'store/email'
import { fundSagas } from 'store/fund'
import { adminOrdersSagas } from 'store/admin_orders'
import { campaignSagas } from 'store/campaign'
import { wishlistSagas } from 'store/wishlist'
import { storiesSagas } from 'store/stories'
import { shippingAddressSagas } from 'store/shipping_address'
import { thankYouEmailSagas } from 'store/thank_you_email'
import { multipleCampaignEmailSagas } from 'store/multiple_campaign_email'
import { paymentReconsolidationSagas } from 'store/reports/payment_reconsolidation'
import { ordersReportSagas } from 'store/reports/orders'
import { pantryStatsSagas } from 'store/pantry/statistics'
import { pantryCampaignSagas } from 'store/pantry/campaign'
import { pantryDonationsSagas } from 'store/pantry/reports/donations'
import { pantryThankYouEmailSagas } from 'store/pantry/emails/thank_you_email'
import { pantryAgreementSagas } from 'store/pantry/agreements'
import { emailSubjectSagas } from 'store/admin/emails/subjects'
import { signUpSagas } from 'store/sign_up'
import { documentationSagas } from 'store/documentation'
import { generalMetricsSagas } from 'store/admin/payouts/general_metrics'
import { userSagas } from './admin/users'

const validKeys = [
  'general',
  'auth',
  'shelter',
  'email',
  'fund',
  'admin_order',
  'campaign',
  'wishlist',
  'stories',
  'shippingAddress',
  'thankYouEmail',
  'multipleCampaignEmail',
  'paymentReconsolidation',
  'ordersReport',
  'pantryStats',
  'pantryCampaign',
  'pantryDonations',
  'pantryThankYouEmail',
  'pantryAgreement',
  'toastr',
  'emailSubject',
  'signUp',
  'documentation',
  'generalMetrics',
  'users'
]
const stateCleanupTransform = createTransform(
  (inboundState) => {
    return Object.keys(inboundState).reduce((acc, key) => {
      if (validKeys.includes(key)) {
        acc[key] = inboundState[key]
      }

      return acc
    }, {})
  },
  null,
  { whitelist: [] }
)
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['general'],
  transforms: [stateCleanupTransform]
}
const sagaMiddleware = createSagaMiddleware()
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
)

const persistor = persistStore(store)

sagaMiddleware.run(authSagas)
sagaMiddleware.run(shelterSagas)
sagaMiddleware.run(emailSagas)
sagaMiddleware.run(fundSagas)
sagaMiddleware.run(adminOrdersSagas)
sagaMiddleware.run(campaignSagas)
sagaMiddleware.run(wishlistSagas)
sagaMiddleware.run(storiesSagas)
sagaMiddleware.run(shippingAddressSagas)
sagaMiddleware.run(thankYouEmailSagas)
sagaMiddleware.run(multipleCampaignEmailSagas)
sagaMiddleware.run(paymentReconsolidationSagas)
sagaMiddleware.run(ordersReportSagas)
sagaMiddleware.run(pantryStatsSagas)
sagaMiddleware.run(pantryCampaignSagas)
sagaMiddleware.run(pantryDonationsSagas)
sagaMiddleware.run(pantryThankYouEmailSagas)
sagaMiddleware.run(pantryAgreementSagas)
sagaMiddleware.run(emailSubjectSagas)
sagaMiddleware.run(signUpSagas)
sagaMiddleware.run(documentationSagas)
sagaMiddleware.run(generalMetricsSagas)
sagaMiddleware.run(userSagas)

export default store

export {
  persistor,
  authActions,
  generalActions,
  shelterActions,
  emailActions,
  fundActions,
  adminOrderActions,
  campaignActions,
  wishlistActions,
  storiesActions,
  shippingAddressActions,
  thankYouEmailActions,
  multipleCampaignEmailActions,
  paymentReconsolidationActions,
  ordersReportActions,
  pantryStatsActions,
  pantryCampaignActions,
  pantryDonationsActions,
  pantryThankYouEmailActions,
  pantryAgreementActions,
  emailSubjectsActions,
  signUpActions,
  documentationActions,
  generalMetricsActions,
  usersActions
}
