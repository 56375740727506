import { lazy } from 'react'

export const adminRoutes = [
  {
    to: '/',
    label: 'Dashboard',
    component: lazy(() => import('containers/Home')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/shelters',
    from: '/admin/shelters',
    label: 'Shelters',
    component: lazy(() => import('containers/shelters/ShelterIndex')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/shelters/create',
    component: lazy(() => import('containers/SignUp')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/adoption',
    from: '/admin/adoption',
    label: 'Adoption',
    component: lazy(() => import('containers/admin/Adoption')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/orders/pending_orders',
    from: '/admin/orders/pending_orders',
    label: 'Orders',
    component: lazy(() => import('containers/orders/PendingOrders')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/orders/manual_orders_report',
    from: '/admin/orders/manual_orders_report',
    component: lazy(() => import('containers/orders/PendingOrdersReport')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/orders/:shelter_id/:shipping_address_id/pending_orders_detail',
    from: '/admin/orders/:shelter_id/:shipping_address_id/pending_orders_detail',
    component: lazy(() => import('containers/orders/PendingOrdersDetail')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/orders/skus_in_limbo',
    from: '/admin/orders/skus_in_limbo',
    component: lazy(() => import('containers/orders/SkuInLimbo')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/orders/parameters',
    from: '/admin/orders/parameters',
    component: lazy(() => import('containers/orders/Parameters')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/orders/pending_products',
    from: '/admin/orders/pending_products',
    component: lazy(() => import('containers/orders/PendingProducts')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/emails',
    from: '/admin/emails',
    label: 'Emails',
    component: lazy(() => import('containers/admin/EmailSubjects')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/emails/pantry-subjects',
    from: '/admin/emails/manage_pantry_subjects',
    component: lazy(() => import('containers/admin/PantryEmailSubject')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/emails/templates',
    from: '/admin/emails/manage_templates',
    component: lazy(() => import('containers/admin/EmailTemplates')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/stories',
    from: '/admin/stories',
    label: 'Stories',
    component: lazy(() => import('containers/admin/ManageSuccessStories')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/payouts/metrics',
    from: '/admin/payouts/metrics',
    label: 'Payouts',
    component: lazy(() => import('containers/admin/Payouts/GeneralMetrics')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/payouts/historical',
    from: '/admin/payouts/payouts_historical',
    component: lazy(() => import('containers/admin/Payouts/HistoricalPayouts')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/payouts/paypal',
    from: '/admin/payouts/paypal_payout',
    component: lazy(() => import('containers/admin/Payouts/PaypalPayout')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/payouts/stripe',
    from: '/admin/payouts/stripe_payout',
    component: lazy(() => import('containers/admin/Payouts/StripePayout')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/users',
    from: '/admin/users',
    label: 'Users',
    component: lazy(() => import('containers/admin/Users')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  }
]

export default adminRoutes
