import { put, call, takeLatest, all } from '@redux-saga/core/effects'
import types from './types'
import actions from './actions'
import axios from 'axios'
import { generalActions } from '../general'
import laravelErrorParser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

// Axios calls
const indexSuccessStory = async ({ payload }) => {
  const { params, isAdmin } = payload
  const url = isAdmin ? 'api/admin/stories' : 'api/common/success-story'

  return await axios.get(url, { params })
}

const getCalculateEmailList = async ({ id }) => {
  return await axios.get(
    `/api/common/success-story/calculate-eligibility/${id}`
  )
}

const postPreviewSuccessStory = async ({ id }) => {
  return await axios({
    method: 'post',
    url: '/api/common/success-story/template-preview/' + id
  })
}

const storeSuccessStory = async (form) => {
  return await axios.post('api/common/success-story', form)
}

const editSuccessStory = async ({ payload }) => {
  const { id, isAdmin } = payload
  const url = isAdmin ? 'api/admin/stories' : 'api/common/success-story'

  return await axios.get(`${url}/${id}/edit`)
}

const updateSuccessStory = async ({ id, form, isAdmin = false }) => {
  const url = isAdmin ? 'api/admin/stories' : 'api/common/success-story'

  return await axios.post(`${url}/${id}`, form)
}

const updateSuccessStoryStatus = async (payload) => {
  const { id, status } = payload

  return await axios.put(`api/admin/stories/${id}/status`, { status })
}

const getSuccessStoryCampaigns = async ({ userId, isAdmin = false }) => {
  return await axios.get(`api/common/success-story/campaigns/${userId}`)
}

const getSuccessStorySubjects = async ({ id }) => {
  return await axios.get(`api/common/success-story/subjects/${id}`)
}

// Doers
function* doDostPreviewSuccessStory(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(postPreviewSuccessStory, payload)

    if (payload?.callback) {
      payload.callback(response.data.preview)
    }
  } catch (err) {
    yield put(generalActions.setError(laravelErrorParser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetCalculateEmailList(payload) {
  try {
    yield put(actions.setExhaustedSuccessStoriesEmailList(true))
    const { data } = yield call(getCalculateEmailList, payload)
    yield put(actions.updateEmailList(data))
  } catch (err) {
    yield put(actions.updateEmailList([]))

    if (err?.response?.status) {
      yield put(generalActions.setError(laravelErrorParser(err)))
      yield put(generalActions.setUnauthorized(err))
    }
  } finally {
    yield put(actions.setExhaustedSuccessStoriesEmailList(false))
  }
}

function* doGetSubjects(payload) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(getSuccessStorySubjects, payload)
    yield put(actions.setSuccessStorySubjects(data?.subjects || []))
    yield put(actions.setSuccessStoryTemplates(data?.templates || []))
    yield put(actions.setSuccessStoryCampaignCover(data?.campaignCover || ''))
  } catch (err) {
    yield put(actions.updateEmailList([]))

    if (err?.response?.status) {
      yield put(generalActions.setError(laravelErrorParser(err)))
      yield put(generalActions.setUnauthorized(err))
    }
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doIndexSuccessStories(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(indexSuccessStory, payload)
    yield put(actions.setSuccessStoryList(response.data))
  } catch (error) {
    yield put(generalActions.setError(laravelErrorParser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doStoreSuccessStory(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(storeSuccessStory, payload.form)
    yield put(generalActions.setSuccess(response.data.message))

    if (payload.callback) {
      payload.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(laravelErrorParser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doEditSuccessStory(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(editSuccessStory, payload)
    yield put(actions.setSuccessStoryForm(response.data))
  } catch (error) {
    yield put(generalActions.setError(laravelErrorParser(error)))
    yield put(generalActions.setUnauthorized(error))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doUpdateSuccessStory(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(updateSuccessStory, payload)
    yield put(generalActions.setSuccess(response.data.message))

    if (payload.callback) {
      payload.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(laravelErrorParser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doUpdateSuccessStoryStatus(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(updateSuccessStoryStatus, payload)
    yield put(generalActions.setSuccess(response.data.message))

    if (payload.callback) {
      payload.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(laravelErrorParser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetSuccessStoryCampaigns(payload) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(getSuccessStoryCampaigns, payload)
    yield put(actions.setSuccessStoryCampaigns(data || []))
  } catch (error) {
    yield put(generalActions.setError(laravelErrorParser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

// Watchers
function* watchDoPostPreviewSuccessStoryEmail() {
  yield takeLatest(
    types.SAGA_POST_PREVIEW_SUCCESS_STORY,
    doDostPreviewSuccessStory
  )
}

function* watchDoGetCalculateEmailList() {
  yield takeLatest(
    types.SAGA_GET_SUCCESS_STORY_CALCULATE_EMAIL_LIST,
    doGetCalculateEmailList
  )
}

function* watchDoGetSubjects() {
  yield takeLatest(types.SAGA_GET_SUCCESS_STORY_SUBJECTS, doGetSubjects)
}

function* watchDoIndexSuccessStories() {
  yield takeLatest(
    types.SAGA_INDEX_SUCCESS_STORY,
    safe(onError, doIndexSuccessStories)
  )
}

function* watchDoStoreSuccessStory() {
  yield takeLatest(
    types.SAGA_STORE_SUCCESS_STORY,
    safe(onError, doStoreSuccessStory)
  )
}

function* watchDoEditSuccessStory() {
  yield takeLatest(
    types.SAGA_EDIT_SUCCESS_STORY,
    safe(onError, doEditSuccessStory)
  )
}

function* watchDoUpdateSuccessStory() {
  yield takeLatest(
    types.SAGA_UPDATE_SUCCESS_STORY,
    safe(onError, doUpdateSuccessStory)
  )
}

function* watchDoUpdateSuccessStoryStatus() {
  yield takeLatest(
    types.SAGA_UPDATE_SUCCESS_STORY_STATUS,
    safe(onError, doUpdateSuccessStoryStatus)
  )
}

function* watchDoGetSuccessStoryCampaigns() {
  yield takeLatest(
    types.SAGA_GET_SUCCESS_STORY_CAMPAIGNS,
    safe(onError, doGetSuccessStoryCampaigns)
  )
}

export default function* rootSaga() {
  yield all([
    watchDoIndexSuccessStories(),
    watchDoStoreSuccessStory(),
    watchDoEditSuccessStory(),
    watchDoUpdateSuccessStory(),
    watchDoGetCalculateEmailList(),
    watchDoGetSubjects(),
    watchDoUpdateSuccessStoryStatus(),
    watchDoPostPreviewSuccessStoryEmail(),
    watchDoGetSuccessStoryCampaigns()
  ])
}
