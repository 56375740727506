import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from '../general'
import actions from './actions'
import errorParser from 'util/laravel_error_parser'

// Axios calls
const getMultipleCampaignEmail = async (campaigns) => {
  return axios({
    method: 'post',
    url: '/api/shelter/multiple-campaign-email',
    data: {
      campaigns
    },
    headers: { 'Content-Type': 'application/json' }
  })
}

const postMultipleCampaignEmail = async (form) => {
  const { subject, sendGroup, campaigns, type, scheduleDate } = form
  const data = {
    subject,
    sendGroup,
    campaigns,
    type
  }

  if (type === 2) {
    data.scheduleDate = scheduleDate
  }

  return await axios.post('/api/shelter/multiple-campaign-email/send', data)
}

// Doers
function* doGetMultipleCampaignEmail({ ids }) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getMultipleCampaignEmail, ids)
    yield put(actions.setMultipleCampaignEmailForm(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doPostMultipleCampaignEmail(action) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(postMultipleCampaignEmail, action.form)
    yield put(actions.resetMultipleCampaignEmailForm())
    yield put(generalActions.setSuccess(response.data.message))

    if (action.callback) {
      action.callback()
    }
  } catch (err) {
    const { response } = err || {}
    const { data } = response || {}
    const { message = 'Bad Request' } = data || {}
    yield put(generalActions.setError(message))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

// Watchers
function* watchDoGetMultipleCampaignEmail() {
  yield takeLatest(
    types.SAGA_GET_MULTIPLE_CAMPAIGN_EMAIL,
    doGetMultipleCampaignEmail
  )
}

function* watchDoPostMultipleCampaignEmail() {
  yield takeLatest(
    types.SAGA_POST_MULTIPLE_CAMPAIGN_EMAIL,
    doPostMultipleCampaignEmail
  )
}

export default function* rootSaga() {
  yield all([
    watchDoGetMultipleCampaignEmail(),
    watchDoPostMultipleCampaignEmail()
  ])
}
